



























import { Component, Vue } from 'vue-property-decorator'

import Loader from '@/components/ui/Loader/Loader.vue'

import ForecastMix from '@/components/data/forecast/ForecastMix.vue'

import { vrsStoreForecast } from '@/store/forecast/store'
import { vrsStoreEvent } from '@/store/event/store'
import { vrsStoreApp } from '@/store/app/store'
import { vrsStoreConfig } from '@/store/config/store'
import { fetchForecastDataGlobal } from '@/store'

import { StateForecast } from '@/store/forecast/definitions'
import { StateApp } from '@/store/app/definitions'

import MixinPageVisibility from '@/mixins/pageVisibility'

@Component({
  components: {
    'ui-loader': Loader,
    'forecast-mix': ForecastMix
  },
  mixins: [
    MixinPageVisibility((isVisible) => {
      if (isVisible && vrsStoreApp.state.data.live) {
        fetchForecastDataGlobal()
      }
    })
  ]

})
export default class Forecast extends Vue {
  stateForecast: StateForecast = vrsStoreForecast.state
  stateEvent = vrsStoreEvent.state
  stateApp: StateApp = vrsStoreApp.state
  stateConfig = vrsStoreConfig.state

  mounted () {
    fetchForecastDataGlobal()
  }
}

